const styles = (theme) => {
  const { baseUnit } = theme;

  return {
    root: {
      paddingTop: baseUnit,
    },
    error: {
      textAlign: 'left',
    }
  };
};

export default styles;
